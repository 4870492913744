/*globals $*/
(function (BDM, heroFinder) {
    'use strict';

    var fObject = (function () {
        let oldDegrees = '',
            oldAreas = '',
            allResults,
            allInterestsT = [],
            allInterestsV = [],
            allDegreesV = [],
            allDegreesT = [];

        function loadData(event) {
            let q = {
                searchsource: $('#filtersource').val(),
                degreetype: 'all',
                interest: 'all',
                waystolearn: $('#waystolearnselected').val() || 'all',
                groupby: $('#filtergroupby').val(),
                pageid: $('#pageitemid').val(),
                programs: $('#programs').val(),
                campus: $('#campuslocation').val()
            };

            $.ajax({
                type: 'POST',
                url: $('.hero-program-finder .degree-finder').data('programsurl'),
                data: q,
                dataType: 'json',
                beforeSend: function () {
                    $('.degree-finder__filter-wrapper').hide();
                    let $container = $('.degree-finder__filter-container');
                    let loader = `
                        <div class="icon__loading white-dots">
                            <div class="snippet" data-title=".dot-falling">
                                <div class="stage">
                                    <div class="dot-falling"></div>
                                </div>
                            </div>
                        </div>
                    `;
                    $container.css('visibility', 'visible');
                    $container.append(loader);
                },
                success: function (data) {
                    allResults = data.programs;
                    $('.icon__loading').remove();
                    $('.degree-finder__filter-wrapper').show();
                }
            });
        }

        function updateSelect(type, typeShow, tShow, vShow) {
            let label = type === 'areas' ? "Areas of Interest" : "Degrees & Programs";
            let optgroup = type === 'areas' ? "All Interests" : "All Degree Types";

            $(`#${type}`).empty();
            $(`#${type}`).append(`<optgroup label="${label}"></optgroup>`);
            let allOpt = typeShow.find(function (e) { return e === 'all'; });
            if (!allOpt) {
                $(`#${type} optgroup`).append(`<option value=all>${optgroup}</option>`)
            }
            for (let i = 0; i < typeShow.length; i++) {
                if (typeShow[i] !== null) {
                    $(`#${type} optgroup`).append(`
                        <option value="${typeShow[i]}">
                            ${tShow[vShow.indexOf(typeShow[i])]}
                        </option>`);
                }
            }
        }

        function filterInterest(term) {
            let typeShow;
            if (term !== "all") {
                let interest = allResults.filter(e => e.degrees[0] === term);
                let interestName = [...new Set(interest.map(e => e.areas[0]))];
                typeShow = interestName.sort();
            } else {
                typeShow = allInterestsV;
            }

            updateSelect('areas', typeShow, allInterestsT, allInterestsV)

            if (typeShow.includes(fObject.oldAreas)) {
                $(`.hero-program-finder #areas`).val(fObject.oldAreas).trigger('change');
            }

        }

        function filterDegree(term) {
            let typeShow;
            if (term !== "all") {
                let degree = allResults.filter(e => e.areas[0] === term);
                let degreeName = [...new Set(degree.map(e => e.degrees[0]))];
                typeShow = degreeName.sort();
            } else {
                typeShow = allDegreesV;
            }
            updateSelect('degrees', typeShow, allDegreesT, allDegreesV);

            if (typeShow.includes(fObject.oldDegrees)) {
                $(`.hero-program-finder #degrees`).val(fObject.oldDegrees).trigger('change');
            }
        }
        return {
            oldDegrees,
            oldAreas,
            allResults,
            allInterestsT,
            allInterestsV,
            allDegreesV,
            allDegreesT,
            filterInterest,
            filterDegree,
            loadData
        };
    })();

    //Events
    $('.hero-program-finder #areas option').each(function () {
        fObject.allInterestsV.push($(this).val());
        fObject.allInterestsT.push($(this).text());
    });

    $('.hero-program-finder #degrees option').each(function () {
        fObject.allDegreesV.push($(this).val());
        fObject.allDegreesT.push($(this).text());
    });

    $('.hero-program-finder #degrees').on('change', function (e) {
        fObject.filterInterest($(this).val());
    });

    heroFinder.init = function () {
        $("document").ready(function () {
            if ($('.hero-program-finder').length > 0) {
                fObject.loadData();
            }
        });
    };
})(
    window.BDM = window.BDM || {},
    window.BDM.heroFinder = window.BDM.heroFinder || {}
);

//load when ready
$(function () {
    window.BDM.heroFinder.init();
});